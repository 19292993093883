<template>
  <BaseButton
    class="library-filter-checkbox-button"
    :outlined="!input"
    :color="input ? 'primary' : ''"
    :disabled="disabled"
    @click="triggerChange"
    >{{ label }} <span class="counter ms-1">{{ counter }}</span></BaseButton
  >
</template>

<script>
import BaseButton from '@/components/base/BaseButton/BaseButton.vue';

export default {
  name: 'LibraryFilterCheckboxButtons',
  components: {
    BaseButton
  },
  props: {
    value: Boolean,
    label: String,
    counter: [String, Number],
    disabled: Boolean
  },
  data() {
    return {
      input: this.value
    };
  },
  watch: {
    value(newValue) {
      this.input = newValue;
    }
  },
  methods: {
    triggerChange() {
      this.input = !this.input;
      this.$emit('input', this.input);
      this.$emit('change', this.input);
    }
  }
};
</script>

<style lang="less">
.library-filter-checkbox-button {
  color: var(--black-color);
  border-color: #e2e8f0;
  .counter {
    color: #94a3b8;
  }
}
</style>
