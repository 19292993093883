<template>
  <div class="library-filter py-2">
    <div class="library-filter-input-wrap align-center">
      <div class="flex-grow-1">
        <BaseTextField
          v-model="filterText"
          clearable
          hide-details
          :outlined="false"
          :placeholder="inputPlaceholder"
          clear-icon="$crossIcon"
          class="library-filter-input-text"
          @input="textInputHandler"
        ></BaseTextField>
      </div>
      <div
        v-if="shouldShowKeyboardControl"
        class="flex-grow-0 virtual-keyboard-control buttons-block"
      >
        <BaseButton outlined @click="toggleVirtualKeyboard">
          <BaseSpriteIcon icon-name="ico-keyboard" />
        </BaseButton>
      </div>
      <div v-if="showExtra" class="flex-grow-0 buttons-block">
        <BaseButton
          class="py-2 px-0"
          min-width="auto"
          text
          small
          :disabled="extraButtonDisabled"
          :class="{ expanded: isAllFilterVisible }"
          aria-label="show filters"
          @click="toggleFilter"
        >
          <BaseSpriteIcon icon-name="ico-filter" />
          <BaseSpriteIcon
            custom-class="chevron-icon"
            icon-name="ico-arrow-chevron"
          />
        </BaseButton>
      </div>
      <div
        v-if="showLanguage && languagesWithoutDefault"
        class="languages-block flex-grow-0 px-2"
      >
        <LanguagesSwitcher
          :hide="isSmallScreen && isMounted"
          :languages-list="languagesList"
          :current-lang="currentLanguage"
          @onLanguageChanged="onLanguageChanged"
        >
          <template #list-item="{item, selected}">
            <div class="library-filter-languages-switcher-menu-item">
              <div
                class="custom-radio"
                :class="{ active: item.value === selected.value }"
              ></div>
              <span>{{ item.text }}</span>
            </div>
          </template>
        </LanguagesSwitcher>
        <div v-show="false">
          <NuxtLink
            v-for="lang in languagesWithoutDefault"
            :key="lang"
            :to="{
              name: MANAGE_PUBLICATION_LANGUAGE,
              params: { pathMatch: lang }
            }"
          />
        </div>
      </div>
    </div>
    <component
      :is="extraFilterComponent"
      v-if="showExtra && isAllFilterVisible"
      @reset="resetFiltersHandler"
      @close="closeExtraFiltersHandler"
    >
      <div class="library-filter-extra delimiter">
        <div
          v-if="isExtendedView"
          class="delimiter library-filter-difficulties d-flex flex-column pt-4 pb-8"
        >
          <div class="d-flex flex-column">
            <h3 class="mb-0">{{ $t('LibraryFilter.section.level') }}</h3>
            <NuxtLink :to="{ name: ASSESSMENT }"
              >{{ $t('LibraryFilter.level.discover') }}
              <sup>&#x2197;</sup></NuxtLink
            >
          </div>
          <DifficultyRange
            v-model="difficultyRange"
            :difficulties="difficulties"
            :difficulties-count="difficultyCount"
            @change="triggerInput"
          />
        </div>
        <div class="flex-grow-1">
          <div
            class="library-filter-checkboxes d-flex flex-wrap py-4"
            :class="{ delimiter: isExtendedView }"
          >
            <LibraryFilterCheckbox
              v-model="offlineBooksOnly"
              :label="$t('LibraryFilter.level.offline')"
              :counter="filterProps.offline"
              @change="triggerInput"
            />
            <LibraryFilterCheckbox
              v-model="booksWithAudioOnly"
              :label="$t('LibraryFilter.level.audio')"
              :counter="filterProps.audio"
              @change="triggerInput"
            />
            <template v-if="isExtendedView">
              <LibraryFilterCheckbox
                v-for="d in durations"
                :key="d.id"
                v-model="durationsModels[d.id]"
                :label="d.label"
                :counter="d.counter"
                @change="triggerInput"
              />
            </template>
          </div>
          <div
            v-if="isExtendedView"
            class="library-filter-checkboxes d-flex flex-wrap py-4"
          >
            <LibraryFilterCheckbox
              v-for="g in genresView"
              :key="g"
              :value="genres[g]"
              :label="g"
              :counter="genresCount[g]"
              @change="changeGenre(g, $event)"
            />
          </div>
        </div>
      </div>
    </component>
    <v-row v-if="showExtra && chips.length && !extraButtonDisabled" no-gutters>
      <v-col class="chips-block">
        <div class="chips-content">
          <v-chip
            v-for="c in chips"
            :key="c.id"
            class="me-2 mt-2"
            :close="c.hasCloseButton"
            close-icon="$crossIcon"
            :outlined="c.hasCloseButton"
            v-on="c.handlers"
          >
            {{ c.label }}
            <span v-if="c.counter" class="ml-1 chip-value">{{
              c.counter
            }}</span>
          </v-chip>
        </div>
      </v-col>
    </v-row>
    <SearchLoader
      v-if="isVirtualKeyboardVisible"
      class="virtual-keyboard-wrapper"
    >
      <sw-virtual-keyboard
        v-model="keyboardModel"
        element-selector=".library-filter-input-text input"
        :language="currentLanguage"
        @input="textInputHandler"
        @close="closeVirtualKeyboard"
      />
    </SearchLoader>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import { mapGetters } from 'vuex';
import SearchPublicationsFactory from '@/classes/factories/SearchPublicationsFactory';

import BrandsEnum from '@shared/enums/BrandsEnum.mjs';
import AppStateEnum from '@/enums/AppStateEnum';
import ManagePublicationsStates from '@/enums/ManagePublicationsStatesEnum';
import FilterDurationRanges from '@/enums/FilterDurationRanges';
import CustomCategoriesEnum from '@shared/enums/CustomCategoriesEnum';

import BaseButton from '@/components/base/BaseButton/BaseButton.vue';
import BaseTextField from '@/components/base/BaseTextField/BaseTextField.vue';
import BaseSpriteIcon from '@/components/base/BaseSpriteIcon/BaseSpriteIcon.vue';
import LanguagesSwitcher from '@/components/base/LanguagesSwitcher/LanguagesSwitcher.vue';
import ExtraFiltersPopup from '@/components/views/LibraryFilter/ExtraFiltersPopup.vue';
import DifficultyRange from '@/components/views/LibraryFilter/DifficultyRange.vue';
import LibraryFilterCheckbox from '@/components/views/LibraryFilter/LibraryFilterCheckbox.vue';
import SearchLoader from '@/components/SearchLoader.vue';

class Chip {
  setId(id) {
    this.id = id;
    return this;
  }
  setLabel(label) {
    this.label = label;
    return this;
  }
  setCounter(counter) {
    this.counter = counter;
    return this;
  }
  setHasCloseButton(val) {
    this.hasCloseButton = val;
    return this;
  }
  setClickHandler(handler) {
    this.clickHandler = handler;
    return this;
  }
  setCloseHandler(handler) {
    this.closeHandler = handler;
    return this;
  }
  build() {
    const handlers = {};
    if (this.clickHandler) {
      handlers.click = this.clickHandler;
    }
    if (this.closeHandler) {
      handlers['click:close'] = this.closeHandler;
    }
    return {
      id: this.id || this.label,
      label: this.label,
      counter: this.counter,
      hasCloseButton: this.hasCloseButton ?? true,
      handlers
    };
  }
}

class Duration {
  setId(id) {
    this.id = id;
    return this;
  }
  setLabel(label) {
    this.label = label;
    return this;
  }
  setCounter(counter) {
    this.counter = counter;
    return this;
  }
  setMin(min) {
    this.min = min;
    return this;
  }
  setMax(max) {
    this.max = max;
    return this;
  }
  build() {
    return {
      id: this.id,
      label: this.label,
      min: this.min,
      max: this.max,
      counter: this.counter
    };
  }
}

const collator = new Intl.Collator(undefined, {
  usage: 'sort',
  numeric: true
});

export default {
  name: 'LibraryFilter',
  components: {
    SearchLoader,
    BaseButton,
    BaseTextField,
    BaseSpriteIcon,
    LanguagesSwitcher,
    ExtraFiltersPopup,
    DifficultyRange,
    LibraryFilterCheckbox
  },
  props: {
    searcher: Object,
    showExtra: {
      type: Boolean,
      default: false
    },
    showLanguage: {
      type: Boolean,
      default: false
    },
    state: {
      type: String
    },
    placeholder: String
  },
  data() {
    const savedCategoriesFilter = this.searcher?.categories?.reduce((r, c) => {
      r[c] = true;
      return r;
    }, {});
    return {
      isLangSwitcherUpdate: false,
      MANAGE_PUBLICATION_LANGUAGE: AppStateEnum.MANAGE_PUBLICATION_LANGUAGE,
      ASSESSMENT: AppStateEnum.ASSESSMENT,
      KEYBOARD_FOR_LANGS: ['ar', 'fa'],
      OCEAN: BrandsEnum.OCEAN,
      isMounted: false,
      filterText: this.searcher?.term || '',
      categories: savedCategoriesFilter || {},
      durationRanges: FilterDurationRanges,
      genres: this.$_getActiveGenresMap(this.searcher?.genres) || {},
      languagesList: this.$store.getters['ContextStore/getLibraryLanguages'],
      durationsModels: this.$_getDurationModelFromSearcher(
        this.searcher?.durations
      ),
      offlineBooksOnly: this.searcher?.offline || false,
      booksWithAudioOnly: this.searcher?.audio || false,
      isAllFilterVisible: false,
      isVirtualKeyboardVisible: false,
      debouncedInput: debounce(this.triggerInput.bind(this), 500),
      difficultyRangeCache: null,
      currentRouteName: this.$route.params.name
    };
  },

  computed: {
    ...mapGetters('LibraryStore', ['getFilterProps', 'searchPublications']),
    ...mapGetters('ContextStore', {
      appState: 'appState',
      currentLanguage: 'currentLanguageGetter',
      brand: 'brand',
      isDevice: 'isDevice',
      isDeviceBrowser: 'isDeviceBrowser'
    }),
    isExtendedView() {
      return this.brand === BrandsEnum.FFA;
    },
    isMobile() {
      return this.isDevice || this.isDeviceBrowser;
    },
    extraButtonDisabled() {
      if (
        this.$store.getters['ContextStore/brand'] !== BrandsEnum.OCEAN ||
        this.appState === AppStateEnum.MANAGE_PUBLICATION ||
        this.appState === AppStateEnum.MANAGE_PUBLICATION_LANGUAGE
      ) {
        return false;
      }
      const searcher = SearchPublicationsFactory.searcherToBuilder(
        this.searcher
      );
      searcher
        .setFilter('')
        .setOffline(false)
        .setAudio(false);

      if (this.searcher?.categories?.includes(CustomCategoriesEnum.NEW)) {
        searcher
          .setState(ManagePublicationsStates.NEW_BOOKS)
          .setAllowBookList(this.$store.getters['LibraryStore/getNewBookIds']);
      }

      return !(this.searchPublications(searcher.build()) || []).some(
        pub => pub.audio
      );
    },
    shouldShowKeyboardControl() {
      const restrictedAppStates =
        this.appState === AppStateEnum.MANAGE_COMPILATION;
      return (
        this.isMounted &&
        !this.isMobile &&
        !restrictedAppStates &&
        this.KEYBOARD_FOR_LANGS.includes(this.currentLanguage)
      );
    },
    inputPlaceholder() {
      return this.placeholder ?? this.$t('LibraryFilter.placeholder');
    },
    filterProps() {
      return this.getFilterProps(this.searcher, this.currentRouteName)
        .filterProps;
    },
    languagesWithoutDefault() {
      const languagesList = this.$store.getters[
        'ContextStore/getLibraryLanguages'
      ];
      const languages = languagesList.filter(lang => lang !== 'en');
      return languages.length ? languages : null;
    },
    isSmallScreen() {
      return this.$store.getters['MediaDetectorStore/mediaSize'].narrow;
    },
    extraFilterComponent() {
      return this.isSmallScreen ? ExtraFiltersPopup : 'div';
    },
    difficulties() {
      return Object.keys(this.filterProps.difficulties)
        .map(d => parseInt(d))
        .sort((a, b) => a - b);
    },
    difficultyCount() {
      return this.getFilterProps(this.searcher, this.currentRouteName)
        .filterPropsWithoutDifficulty.difficulties;
    },
    difficultyRange: {
      get() {
        if (this.difficultyRangeCache) {
          return this.difficultyRangeCache;
        }
        const difficulty = this.searcher?.difficultyRange;
        if (difficulty) {
          const min = this.difficulties.indexOf(difficulty[0]);
          const max = this.difficulties.indexOf(difficulty[1]);
          return min > -1 && max > -1 ? [min, max] : null;
        }
        return null;
      },
      set(val) {
        this.difficultyRangeCache = val;
      }
    },
    durationCount() {
      return this.getFilterProps(this.searcher, this.currentRouteName)
        .filterPropsWithoutDurations.durations;
    },
    genresView() {
      const genresKeys = Object.keys(this.filterProps.genres);
      return genresKeys.length ? genresKeys.sort(collator.compare) : [];
    },
    genresCount() {
      return this.getFilterProps(this.searcher, this.currentRouteName)
        .filterPropsWithoutGenres.genres;
    },
    chips() {
      const chips = [];
      if (!this.showExtra) {
        return chips;
      }
      if (this.isExtendedView) {
        const genresChips = Object.entries(this.genres)
          .reduce((result, [key, val]) => {
            if (val) {
              result.push(this.$_getGenreChip(key));
            }
            return result;
          }, [])
          .sort((a, b) => collator.compare(a.id, b.id));
        chips.push(...genresChips);
      }

      const rangeChips = this.$_getDifficultyChip();
      if (rangeChips) {
        chips.push(rangeChips);
      }

      const durationChips = Object.keys(this.durationsModels).reduce(
        (result, key) => {
          if (this.durationsModels[key]) {
            result.push(this.$_getDurationChip(key));
          }
          return result;
        },
        []
      );
      chips.push(...durationChips);

      if (this.booksWithAudioOnly) {
        chips.push(this.$_getAudioChip());
      }
      if (this.offlineBooksOnly) {
        chips.push(this.$_getOfflineChip());
      }
      if (chips.length) {
        chips.unshift(this.$_getResetChip());
      }
      return chips;
    },
    durations() {
      const durationsMap = this.durationRanges.map(([key, min, max]) => {
        const duration = new Duration();
        duration
          .setId(key)
          .setMin(min)
          .setMax(max)
          .setLabel(this.$_getDurationLabel(key))
          .setCounter(this.durationCount[key]);
        return duration.build();
      });
      return durationsMap;
    },
    keyboardModel: {
      get() {
        return this.filterText || '';
      },
      set(value) {
        this.filterText = value;
      }
    }
  },
  watch: {
    currentLanguage() {
      if (!this.isLangSwitcherUpdate) {
        this.triggerInput();
      }

      this.isLangSwitcherUpdate = false;
    }
  },
  mounted() {
    this.isMounted = true;
  },
  methods: {
    $_getDurationModelFromSearcher(durations) {
      if (!durations) {
        return {};
      }
      const models = {};
      durations.forEach(([min, max]) => {
        const range = FilterDurationRanges.find(
          ([, dMin, dMax]) => dMin === min && dMax === max
        );
        if (range) {
          models[range[0]] = true;
        }
      });
      return models;
    },
    $_getDurationLabel(name) {
      return this.$t(`LibraryFilter.duration.${name}`);
    },
    $_getActiveGenresMap(genres) {
      if (!genres?.length) {
        return;
      }
      const genresObj = {};
      genres.forEach(genre => {
        genresObj[genre] = true;
      });
      return genresObj;
    },
    resetFiltersHandler() {
      this.closeExtraFiltersHandler();
      this.$_resetFilters();
    },
    closeExtraFiltersHandler() {
      this.isAllFilterVisible = false;
    },
    toggleFilter() {
      this.isAllFilterVisible = !this.isAllFilterVisible;
    },
    toggleVirtualKeyboard() {
      this.isVirtualKeyboardVisible = !this.isVirtualKeyboardVisible;
    },
    closeVirtualKeyboard() {
      this.isVirtualKeyboardVisible = false;
    },
    onLanguageChanged({ language }) {
      this.isLangSwitcherUpdate = true;
      this.$store.commit('ContextStore/setCurrentLanguage', language);
      if (
        language === 'en' &&
        AppStateEnum.MANAGE_PUBLICATION !== this.$route.name
      ) {
        this.$router.push({
          name: AppStateEnum.MANAGE_PUBLICATION
        });
      } else {
        this.$router.push({
          name: AppStateEnum.MANAGE_PUBLICATION_LANGUAGE,
          params: { pathMatch: language }
        });
      }
      this.resetFiltersHandler();
      this.triggerInput();
    },

    // --- chips ---
    $_getResetChip() {
      const c = new Chip();
      c.setId('reset')
        .setLabel(this.$t('LibraryFilter.level.reset'))
        .setHasCloseButton(false)
        .setClickHandler(this.$_resetFilters.bind(this));
      return c.build();
    },
    $_getGenreChip(val) {
      const c = new Chip();
      c.setId(val)
        .setLabel(val)
        .setCounter(this.filterProps.genres[val])
        .setCloseHandler(this.$_closeChipGenre.bind(this, val));
      return c.build();
    },
    $_getDifficultyChip() {
      const range = this.difficultyRange;
      const rangeChanged =
        range && (range[0] !== 0 || range[1] !== this.difficulties.length - 1);
      if (!rangeChanged) {
        return;
      }
      let count = 0;
      for (let i = range[0]; i <= range[1]; i++) {
        count += this.filterProps.difficulties[this.difficulties[i]];
      }
      const levels =
        range[0] === range[1]
          ? this.difficulties[range[0]]
          : `${this.difficulties[range[0]]} - ${this.difficulties[range[1]]}`;
      const c = new Chip();
      c.setId('d-range')
        .setLabel(`${this.$t('LibraryFilter.section.level')} ${levels}`)
        .setCounter(count)
        .setCloseHandler(this.$_closeRangeChip.bind(this));
      return c.build();
    },
    $_getDurationChip(val) {
      const c = new Chip();
      c.setId(val)
        .setLabel(this.$_getDurationLabel(val))
        .setCounter(this.durationCount[val])
        .setCloseHandler(this.$_closeChipDuration.bind(this, val));
      return c.build();
    },
    $_getAudioChip() {
      const c = new Chip();
      c.setId('with-audio')
        .setLabel(this.$t('LibraryFilter.level.audio'))
        .setCounter(this.filterProps.audio)
        .setCloseHandler(this.$_closeWithAudioChip.bind(this));
      return c.build();
    },
    $_getOfflineChip() {
      const c = new Chip();
      c.setId('offline')
        .setLabel(this.$t('LibraryFilter.level.offline'))
        .setCounter(this.filterProps.offline)
        .setCloseHandler(this.$_closeOfflineChip.bind(this));
      return c.build();
    },
    $_resetFilters() {
      this.filterText = '';
      if (this.isExtendedView) {
        this.genres = {};
      }
      this.durationsModels = {};
      this.difficultyRange = [0, this.difficulties.length - 1];
      this.booksWithAudioOnly = false;
      this.offlineBooksOnly = false;
      this.triggerInput();
    },
    $_closeChipGenre(name) {
      this.genres = { ...this.genres, [name]: false };
      this.triggerInput();
    },
    $_closeChipDuration(id) {
      this.durationsModels = { ...this.durationsModels, [id]: false };
      this.triggerInput();
    },
    $_closeRangeChip() {
      this.difficultyRange = [0, this.difficulties.length - 1];
      this.triggerInput();
    },
    $_closeWithAudioChip() {
      this.booksWithAudioOnly = false;
      this.triggerInput();
    },
    $_closeOfflineChip() {
      this.offlineBooksOnly = false;
      this.triggerInput();
    },
    // --- chips ---

    $_isFiltersChange() {
      const isGenresChanged =
        this.isExtendedView &&
        Object.keys(this.genres).some(g => this.genres[g]);
      const isDurationsChanged = Object.keys(this.durationsModels).some(
        d => this.durationsModels[d]
      );
      const isRangeChanged =
        this.isExtendedView &&
        this.difficultyRange &&
        (this.difficultyRange[0] !== 0 ||
          this.difficultyRange[1] !== this.difficulties.length - 1);

      return (
        this.booksWithAudioOnly ||
        this.offlineBooksOnly ||
        isGenresChanged ||
        isDurationsChanged ||
        isRangeChanged
      );
    },
    textInputHandler(val) {
      if (val) {
        return this.debouncedInput();
      }
      this.triggerInput();
    },
    changeGenre(genre, val) {
      this.genres = { ...this.genres, [genre]: val };
      this.triggerInput();
    },
    triggerInput() {
      const _getSelectedProps = obj => {
        return Object.entries(obj).reduce((result, [key, val]) => {
          if (val) {
            result.push(key);
          }
          return result;
        }, []);
      };
      const categories = _getSelectedProps(this.categories);
      const genres = _getSelectedProps(this.genres);
      const recentBookIds = this.categories.recent
        ? this.$store.getters['RecentBookStore/getRecentBookIds']
        : null;
      const newBookIds = this.categories.new
        ? this.$store.getters['LibraryStore/getNewBookIds']
        : null;
      const currentLanguage = this.$store.getters[
        'ContextStore/currentLanguageGetter'
      ];
      const durations = Object.keys(this.durationsModels).reduce(
        (result, key) => {
          if (this.durationsModels[key]) {
            const duration = this.durations.find(d => d.id === key);
            result.push([duration.min, duration.max]);
          }
          return result;
        },
        []
      );
      const difficultRange =
        this.difficultyRange &&
        (this.difficultyRange[0] !== 0 ||
          this.difficultyRange[1] !== this.difficulties.length - 1)
          ? [
              this.difficulties[this.difficultyRange[0]],
              this.difficulties[this.difficultyRange[1]]
            ]
          : null;
      const builder = SearchPublicationsFactory.getSearcherBuilder();
      builder
        .setState(
          this.state || this.searcher?.state || ManagePublicationsStates.LIBRARY
        )
        .setLanguage(currentLanguage)
        .setCategories(categories)
        .setAudio(this.booksWithAudioOnly)
        .setOffline(this.offlineBooksOnly)
        .setDifficultyRange(difficultRange)
        .setDurations(durations)
        .setAllowBookList(recentBookIds || newBookIds)
        .setCollectionIncluded(!!this.filterText || this.$_isFiltersChange())
        .setFilter(this.filterText)
        .setGenres(genres)
        .setAuthorSlug(this.searcher?.authorSlug)
        .setCollectionId(this.searcher?.collectionId);
      const searcher = builder.build();
      this.$emit('change', searcher);
    }
  }
};
</script>

<style lang="less" src="./LibraryFilter.less"></style>
